import useStore from "../config/store";

const TOTAL_CLIENTS_OR_PROVIDERS = 3;
const TOTAL_ADD_COMPANIES = 3;
const TOTAL_UPDATE_COMPANIES = 2;
const TOTAL_ASSIGNMENTS = 2;
const TOTAL_LEGAL = 2;
const TOTAL_USERS = 3;
const TOTAL_COUNTER_PARTS = 2;
const TOTAL_VATS = 2;

export default function usePermissions() {
  const userData = useStore(state => state.userData);

  const permissions = userData?.permissions;

  // Accounting Firms Permissions
  const canDisplayAccountingFirm = () =>
    permissions && !!permissions.find(item => item === "dashboard.can-display-accounting-firms");

  const canCreateAccountingFirm = () =>
    permissions && !!permissions.find(item => item === "central-api.can-create-accounting-firms");

  const canUpdateAccountingFirm = () =>
    permissions && !!permissions.find(item => item === "central-api.can-update-accounting-firms");

  // Accounting firm Address
  const canDisplayAccountingFirmAddress = () =>
    permissions &&
    !!permissions.find(item => item === "dashboard.can-display-accounting-firm-addresses");

  // Accounting firm contacts
  const canDisplayAccountingFirmContacts = () =>
    permissions &&
    !!permissions.find(item => item === "dashboard.can-display-accounting-firm-contacts");

  // Accounting Firm Settings
  const canDisplayAccountingFirmSettings = () =>
    permissions &&
    !!permissions.find(item => item === "dashboard.can-display-accounting-firm-settings");
  const canViewAccountingFirmSettings = () =>
    permissions &&
    !!permissions.find(item => item === "central-api.can-view-accounting-firm-settings");
  const canViewAccountingFirm = () =>
    permissions && !!permissions.find(item => item === "central-api.can-view-accounting-firms");

  const canUpdateAccountingFirmSettings = () =>
    permissions &&
    !!permissions.find(item => item === "central-api.can-update-accounting-firm-settings");

  // Accounting Firms Platform
  const canDisplayPlatforms = () =>
    permissions && !!permissions.find(item => item === "dashboard.can-display-platforms");

  const canCreatePlatforms = () =>
    permissions && !!permissions.find(item => item === "central-api.can-create-platform");

  const canUpdatePlatforms = () =>
    permissions && !!permissions.find(item => item === "central-api.can-update-platform");

  const canDeletePlatforms = () =>
    permissions && !!permissions.find(item => item === "central-api.can-delete-platform");

  // Accounting Firms Banks Permissions
  const canDisplayAccountingFirmsBanks = () =>
    permissions &&
    !!permissions.find(item => item === "dashboard.can-display-accounting-firms-banks");

  const canViewAccountingFirmsBanks = () =>
    permissions &&
    !!permissions.find(item => item === "central-api.can-view-accounting-firms-banks");

  // Books Permissions
  const canDisplayBooks = () =>
    permissions && !!permissions.find(item => item === "dashboard.can-display-books");

  const canCreateBooks = () =>
    permissions && !!permissions.find(item => item === "central-api.can-create-books");

  const canUpdateBooks = () =>
    permissions && !!permissions.find(item => item === "central-api.can-update-books");

  const canUpdateBookTypesAllBooks = () =>
    permissions &&
    !!permissions.find(item => item === "central-api.can-update-book-types-all-books");

  // Clients Permissions
  const canDisplayClients = () =>
    permissions && !!permissions.find(item => item === `dashboard.can-display-clients`);

  const canDisplayProviders = () =>
    permissions && !!permissions.find(item => item === `dashboard.can-display-providers`);

  const canListClientsOrProviders = type =>
    permissions && !!permissions.find(item => item === `central-api.can-view-${type}`);

  const canUpdateClientsOrProviders = type => {
    const permissionsList =
      permissions &&
      permissions.filter(
        item =>
          item === `central-api.can-update-${type}` ||
          item === "central-api.can-view-counter-parts" ||
          item === "central-api.can-view-vats",
      );

    return permissions && permissionsList.length === TOTAL_CLIENTS_OR_PROVIDERS;
  };

  // Companies Permissions
  const canDisplayCompany = () =>
    permissions && !!permissions.find(item => item === "dashboard.can-display-companies");
  const canDeleteCompany = () =>
    permissions && !!permissions.find(item => item === "central-api.can-delete-company");

  const canListCompany = () =>
    permissions && !!permissions.find(item => item === "central-api.can-view-companies");

  const canCreateCompany = () => {
    const permissionsList =
      permissions &&
      permissions.filter(
        item =>
          item === "central-api.can-create-companies" ||
          item === "central-api.can-view-accounting-firms" ||
          item === "central-api.can-view-platform",
      );
    return permissions && permissionsList.length === TOTAL_ADD_COMPANIES;
  };

  const canUpdateCompany = () => {
    const permissionsList =
      permissions &&
      permissions.filter(
        item =>
          item === "central-api.can-update-companies" || item === "central-api.can-view-platform",
      );
    return permissions && permissionsList.length === TOTAL_UPDATE_COMPANIES;
  };

  const canUpdateCompanySettings = () =>
    permissions && !!permissions.find(item => item === "central-api.can-update-company-settings");

  const canDisplayCompanySettings = () =>
    permissions && !!permissions.find(item => item === "dashboard.can-display-company-settings");

  // Company General Permissions List
  const canDisplayCompanyGeneral = () =>
    permissions && !!permissions.find(item => item === "dashboard.can-display-companies-general");

  // Companies Banks Permissions
  const canDisplayCompaniesBanks = () =>
    permissions && !!permissions.find(item => item === "dashboard.can-display-companies-banks");

  const canManageCompaniesBanks = () =>
    permissions && !!permissions.find(item => item === "neolib-api.can-manage-banks");

  // Company Assignments Permissions List
  const canDisplayAssignments = () =>
    permissions && !!permissions.find(item => item === "dashboard.can-display-assignments");

  const canDisplayCompanyAddress = () =>
    permissions && !!permissions.find(item => item === "dashboard.can-display-company-address");

  const canCreateAssignments = () => {
    const permissionsList =
      permissions &&
      permissions.filter(
        item => item === "central-api.can-create-assignments" || item === "neoauth.can-view-users",
      );
    return permissions && permissionsList.length === TOTAL_ASSIGNMENTS;
  };

  const canUpdateAssignments = () => {
    const permissionsList =
      permissions &&
      permissions.filter(
        item => item === "central-api.can-update-assignments" || item === "neoauth.can-view-users",
      );
    return permissions && permissionsList.length === TOTAL_ASSIGNMENTS;
  };

  const canDeleteAssignments = () =>
    permissions && !!permissions.find(item => item === "central-api.can-delete-assignment");

  // Company Legal Permissions List
  const canDisplayCompanyLegal = () =>
    permissions && !!permissions.find(item => item === "dashboard.can-display-companies-legal");

  const canUpdateFiscal = () =>
    permissions && !!permissions.find(item => item === "central-api.can-update-fiscals");

  const canDisplayCorporateDirectors = () => {
    const permissionsList =
      permissions &&
      permissions.filter(
        item =>
          item === "central-api.can-view-corporate-members" ||
          item === "dashboard.can-display-corporate-members-is-director",
      );
    return permissions && permissionsList.length === TOTAL_LEGAL;
  };

  const canDeleteCorporateMembers = () =>
    permissions && !!permissions.find(item => item === "central-api.can-delete-corporate-members");

  const canCreateCorporateDirectors = () => {
    const permissionsList =
      permissions &&
      permissions.filter(
        item =>
          item === "central-api.can-create-corporate-members" ||
          item === "central-api.can-create-corporate-members-is-director",
      );
    return permissions && permissionsList.length === TOTAL_LEGAL;
  };

  const canUpdateCorporateDirectors = () => {
    const permissionsList =
      permissions &&
      permissions.filter(
        item =>
          item === "central-api.can-update-corporate-members" ||
          item === "central-api.can-update-corporate-members-is-director",
      );
    return permissions && permissionsList.length === TOTAL_LEGAL;
  };

  const canDisplayCorporateStakeholders = () => {
    const permissionsList =
      permissions &&
      permissions.filter(
        item =>
          item === "central-api.can-view-corporate-members" ||
          item === "dashboard.can-display-corporate-members-is-stakeholder",
      );
    return permissions && permissionsList.length === TOTAL_LEGAL;
  };

  const canCreateCorporateStakeholders = () => {
    const permissionsList =
      permissions &&
      permissions.filter(
        item =>
          item === "central-api.can-create-corporate-members" ||
          item === "central-api.can-create-corporate-members-is-stakeholder",
      );
    return permissions && permissionsList.length === TOTAL_LEGAL;
  };

  const canUpdateCorporateStakeholders = () => {
    const permissionsList =
      permissions &&
      permissions.filter(
        item =>
          item === "central-api.can-update-corporate-members" ||
          item === "central-api.can-update-corporate-members-is-stakeholder",
      );
    return permissions && permissionsList.length === TOTAL_LEGAL;
  };

  // Import /  Permissions List
  const canImport = () =>
    permissions && !!permissions.find(item => item === "central-api.can-import");

  const canExport = () =>
    permissions && !!permissions.find(item => item === "central-api.can-export");

  const canImportExport = () =>
    permissions &&
    (!!permissions.find(item => item === "central-api.can-import") ||
      !!permissions.find(item => item === "central-api.can-export"));
  // Counter Parts Permissions
  const canDisplayCounterParts = () =>
    permissions && !!permissions.find(item => item === "dashboard.can-display-counter-parts");

  const canCreateCounterParts = () => {
    const permissionsList =
      permissions &&
      permissions.filter(
        item =>
          item === "central-api.can-create-counter-parts" || item === "central-api.can-view-vats",
      );
    return permissions && permissionsList.length === TOTAL_COUNTER_PARTS;
  };

  const canUpdateCounterParts = () => {
    const permissionsList =
      permissions &&
      permissions.filter(
        item =>
          item === "central-api.can-update-counter-parts" || item === "central-api.can-view-vats",
      );
    return permissions && permissionsList.length === TOTAL_COUNTER_PARTS;
  };

  // Users Permissions
  const canDisplayUser = () =>
    permissions && !!permissions.find(item => item === "dashboard.can-display-users");

  const canViewDossierProgress = () =>
    permissions && !!permissions.find(item => item === "neoauth.can-view-dossier-progress");

  const canCreateUser = () => {
    const permissionsList =
      permissions &&
      permissions.filter(
        item =>
          item === "neoauth.can-create-users" ||
          item === "central-api.can-view-companies" ||
          item === "central-api.can-view-accounting-firms",
      );
    return permissions && permissionsList.length === TOTAL_USERS;
  };

  const canUpdateUser = () => {
    const permissionsList =
      permissions &&
      permissions.filter(
        item =>
          item === "neoauth.can-update-users" ||
          item === "central-api.can-view-companies" ||
          item === "central-api.can-view-accounting-firms",
      );
    return permissions && permissionsList.length === TOTAL_USERS;
  };

  const canAddRemoveAddon = () =>
    permissions && !!permissions.find(item => item === "neoauth.can-remove-and-add-addon");

  const canUpdateProfile = () =>
    permissions && !!permissions.find(item => item === "neoauth.can-update-profile");

  // Vats Permissions
  const canDisplayVats = () =>
    permissions && !!permissions.find(item => item === "dashboard.can-display-vats");
  const canDeleteVats = () =>
    permissions && !!permissions.find(item => item === "dashboard.can-delete-vats");

  const canCreateVats = () =>
    permissions && !!permissions.find(item => item === "central-api.can-create-vats");

  const canUpdateVats = () => {
    const permissionsList =
      permissions &&
      permissions.filter(
        item =>
          item === "central-api.can-update-vats" || item === "central-api.can-view-counter-parts",
      );
    return permissions && permissionsList.length === TOTAL_VATS;
  };

  // Addons/services Permisstions
  const canDisplayServices = () =>
    permissions && !!permissions.find(item => item === "dashboard.can-display-services");

  const canCreateSubscriptions = () =>
    permissions && !!permissions.find(item => item === "crm-api.can-create-subscriptions");

  const canUpdateSubscriptions = () =>
    permissions && !!permissions.find(item => item === "crm-api.can-update-subscriptions");

  const canDisplayCycles = () =>
    permissions && !!permissions.find(item => item === "dashboard.can-display-cycles");

  const canUpdateCycles = () =>
    permissions && !!permissions.find(item => item === "central-api.can-update-cycles");

  const canDisplaySubsidiaries = () =>
    permissions && !!permissions.find(item => item === "dashboard.can-display-subsidiaries");

  const canRegister = () =>
    permissions &&
    permissions.find(
      item => item === "neossi.can-register" || item === "neossi-api.can-register-book-entries",
    );

  const canCreateClient = () =>
    permissions && permissions.find(item => item === "central-api.can-create-clients");

  const canCreateProvider = () =>
    permissions && permissions.find(item => item === "central-api.can-create-providers");

  // View full list of users
  const canDisplayAllUsers = () => {
    return permissions && !!permissions.find(item => item === `dashboard.can-display-all-users`);
  };

  return {
    canDisplayAccountingFirm,
    canCreateAccountingFirm,
    canUpdateAccountingFirm,
    canDisplayAccountingFirmAddress,
    canDisplayAccountingFirmContacts,
    canDisplayAccountingFirmSettings,
    canViewAccountingFirmSettings,
    canViewAccountingFirm,
    canUpdateAccountingFirmSettings,
    canDisplayPlatforms,
    canCreatePlatforms,
    canUpdatePlatforms,
    canDeletePlatforms,
    canDisplayAccountingFirmsBanks,
    canViewAccountingFirmsBanks,
    canDisplayBooks,
    canCreateBooks,
    canUpdateBooks,
    canViewDossierProgress,
    canUpdateBookTypesAllBooks,
    canDisplayClients,
    canDisplayProviders,
    canListClientsOrProviders,
    canUpdateClientsOrProviders,
    canUpdateFiscal,
    canManageCompaniesBanks,
    canDisplayCompany,
    canListCompany,
    canCreateCompany,
    canUpdateCompany,
    canUpdateCompanySettings,
    canDisplayCompanySettings,
    canDisplayCycles,
    canDisplayCompanyGeneral,
    canDisplayCompaniesBanks,
    canDisplayAssignments,
    canDisplayCompanyAddress,
    canCreateAssignments,
    canDeleteAssignments,
    canUpdateAssignments,
    canDisplayCompanyLegal,
    canDisplayCorporateDirectors,
    canCreateCorporateDirectors,
    canUpdateCorporateDirectors,
    canDisplayCorporateStakeholders,
    canCreateCorporateStakeholders,
    canUpdateCorporateStakeholders,
    canImport,
    canExport,
    canImportExport,
    canDisplayCounterParts,
    canCreateCounterParts,
    canUpdateCounterParts,
    canDisplayUser,
    canDisplaySubsidiaries,
    canCreateUser,
    canUpdateUser,
    canAddRemoveAddon,
    canUpdateProfile,
    canDisplayVats,
    canCreateVats,
    canUpdateVats,
    canDisplayServices,
    canCreateSubscriptions,
    canUpdateSubscriptions,
    canDeleteCompany,
    canUpdateCycles,
    canDeleteVats,
    canRegister,
    canCreateClient,
    canCreateProvider,
    canDisplayAllUsers,
    canDeleteCorporateMembers,
  };
}
