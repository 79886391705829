import usePermissions from "hooks/usePermissions";

export function usePermissionsRedirects(id: ID) {
  const {
    canCreateAccountingFirm,
    canCreateCompany,
    canCreateUser,
    canDisplayAccountingFirm,
    canDisplayCompany,
    canDisplayUser,
    canUpdateAccountingFirm,
    canUpdateCompany,
    canUpdateProfile,
    canUpdateUser,
    canDisplayCompanyGeneral,
    canDisplayCompaniesBanks,
    canDisplayAssignments,
    canDisplayCompanyLegal,
    canDisplayCorporateDirectors,
    canDisplayCorporateStakeholders,
    canImport,
    canExport,
    canDisplayBooks,
    canDisplayVats,
    canDisplayCounterParts,
    canDisplayCompanySettings,
    canDisplayCompanyAddress,
    canDisplayClients,
    canDisplayProviders,
    canDisplayCycles,
    canDisplaySubsidiaries,
    canImportExport,
    canViewDossierProgress,
  } = usePermissions();

  const permissionsRedirects = {
    displayAccountingFirms: {
      hasPermission: canDisplayAccountingFirm,
      redirectUrl: "/",
    },
    createAccountingFirms: {
      hasPermission: canCreateAccountingFirm,
      redirectUrl: "/accounting-firms",
    },
    updateAccountingFirms: {
      hasPermission: canUpdateAccountingFirm,
      redirectUrl: `/accounting-firms/${id}`,
    },
    displayUsers: {
      hasPermission: canDisplayUser,
      redirectUrl: "/",
    },
    createUsers: {
      hasPermission: canCreateUser,
      redirectUrl: "/users",
    },
    updateUsers: {
      hasPermission: canUpdateUser,
      redirectUrl: "/users",
    },
    displayCompanies: {
      hasPermission: canDisplayCompany,
      redirectUrl: "/",
    },
    createCompanies: {
      hasPermission: canCreateCompany,
      redirectUrl: "/companies",
    },
    updateCompanies: {
      hasPermission: canUpdateCompany,
      redirectUrl: "/companies",
    },
    updateProfile: {
      hasPermission: canUpdateProfile,
      redirectUrl: "/",
    },
    canDisplayCompanyGeneral: {
      hasPermission: canDisplayCompanyGeneral,
      redirect: "/companies",
    },
    canDisplayCompaniesBanks: {
      hasPermission: canDisplayCompaniesBanks,
      redirect: "/companies",
    },
    canDisplayAssignments: {
      hasPermission: canDisplayAssignments,
      redirect: "/companies",
    },
    canDisplayCompanyLegal: {
      hasPermission: canDisplayCompanyLegal,
      redirect: "/companies",
    },
    canDisplayCorporateDirectors: {
      hasPermission: canDisplayCorporateDirectors,
      redirect: "/companies",
    },
    canDisplayCorporateStakeholders: {
      hasPermission: canDisplayCorporateStakeholders,
      redirect: "/companies",
    },
    canImport: {
      hasPermission: canImport,
      redirect: "/companies",
    },
    canExport: {
      hasPermission: canExport,
      redirect: "/companies",
    },
    canDisplayBooks: {
      hasPermission: canDisplayBooks,
      redirect: "/companies",
    },
    canDisplayVats: {
      hasPermission: canDisplayVats,
      redirect: "/companies",
    },
    canDisplayCounterParts: {
      hasPermission: canDisplayCounterParts,
      redirect: "/companies",
    },
    canDisplayCompanySettings: {
      hasPermission: canDisplayCompanySettings,
      redirect: "/companies",
    },
    canDisplayCompanyAddress: {
      hasPermission: canDisplayCompanyAddress,
      redirect: "/companies",
    },
    canDisplayClients: {
      hasPermission: canDisplayClients,
      redirect: "/companies",
    },
    canDisplayProviders: {
      hasPermission: canDisplayProviders,
      redirect: "/companies",
    },
    canDisplayCycles: {
      hasPermission: canDisplayCycles,
      redirect: "/companies",
    },
    canDisplaySubsidiaries: {
      hasPermission: canDisplaySubsidiaries,
      redirect: "/companies",
    },
    canImportExport: {
      hasPermission: canImportExport,
      redirect: "/companies",
    },
    canViewDossierProgress: {
      hasPermission: canViewDossierProgress,
      redirect: "/companies",
    },
  };

  return { permissionsRedirects };
}
