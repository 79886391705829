import useStore from "config/store";
import { useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";
import { routers } from "./index";
import { usePermissionsRedirects } from "./usePermissionsRedirects";

interface PermissionRedirectProps {
  children: React.ReactNode;
  requiredPermission?: string;
}

export default function PermissionRedirect(props: PermissionRedirectProps) {
  const { requiredPermission } = props;
  const userData = useStore(state => state.userData);

  const location = useLocation();
  const { userId } = useParams();

  const { pathname } = location;

  const { permissionsRedirects } = usePermissionsRedirects(userId);

  const checkUserPermissions = user => {
    const response = { hasPermission: true, redirectUrl: "" };

    if (userId && pathname.includes("/accounting-firms/")) {
      response.hasPermission = user.accountingFirms.includes(userId) || user.isSuperuser;
      response.redirectUrl = "/accounting-firms";
    } else if (userId && pathname.includes("/companies/")) {
      response.hasPermission = user.companies.includes(userId) || user.isSuperuser;
      response.redirectUrl = "/companies";
    }

    if (!requiredPermission || !response.hasPermission) {
      return response;
    }

    const { hasPermission, redirectUrl } = permissionsRedirects[requiredPermission];
    return { hasPermission: hasPermission(), redirectUrl };
  };

  useEffect(() => {
    if (userData) {
      const { hasPermission, redirectUrl } = checkUserPermissions(userData);
      if (!hasPermission) {
        routers.navigate(redirectUrl, { replace: true });
      }
    }
  }, [requiredPermission]);

  return <>{props.children}</>;
}
